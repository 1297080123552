#progress-chart-phase-popup {
  position: absolute;
  width: 400px;
  max-height: 400px;
  padding: 10px;
  overflow-y: auto;
  z-index: 3;
}

#progress-chart-phase-popup .phase-popup__header {
  text-align: center;
  font-size: 19px;
}

#progress-chart-phase-popup .item {
  margin: 1em 0;
}

#progress-chart-phase-popup .item:first-child {
  margin-top: 5px;
}

#progress-chart-phase-popup .item:last-child {
  margin-bottom: 0;
}