#login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 24px;
  background: '#ECECEC';
}

#login-form .input-field {
  margin: 16px 0;
}

#login-form .login-button {
  margin: 16px 0;
  width: 240px;
}

#login-form .error-message {
  color: red;
  margin-bottom: 8px;
}
