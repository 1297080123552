.list-panel {
  overflow-y: auto;
}

.list-panel .list-header-wrapper {
  padding: 5px 0;
  border-bottom: 1px solid var(--grey300);
}

.list-panel .list-header,
.list-panel > ul > li {
  display: flex;
  padding: 0 15px;
}

.list-panel > ul {
  margin: 0;
  padding: 0;
}

.list-panel > ul > li:not(:last-child) {
  border-bottom: 2px solid var(--grey300);
}

.list-panel > ul > li {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.list-panel > ul > li:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.list-panel .item-last {
  border-bottom: none !important;
}

.list-panel .arrow-icon {
  width: 10px;
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  vertical-align: top;
}

/**
* Styles for child components.
*/
.list-panel .list-title {
  font-size: 14px;
  font-weight: 500;
}

.list-panel .list-title-right {
  margin-left: auto;
}

.list-panel .link {
  display: flex;
  width: 100%;
  text-decoration: none;
  color: inherit;
}

.list-panel .single-row {
  line-height: 60px;
}

.list-panel .description {
  padding-left: 15px;
  overflow: hidden;
  margin-right: 20px;
}

.list-panel .top-desc,
.list-panel .bottom-desc {
  height: 30px;
}

.list-panel .top-desc {
  line-height: 40px;
}

.list-panel .bottom-desc {
  font-size: 14px;
  color: var(--grey400);
}

.list-panel .sort {
  cursor: pointer;
}

.list-panel .icon {
  font-size: 30px;
  color: var(--grey400);
  margin: 15px 0;
}

.list-panel .avatar {
  margin: 10px 0;
  height: 40px;
  width: 40px;
}

.list-panel .right-block {
  display: flex;
  margin-left: auto;
}

.list-panel .percent,
.list-panel .right-text-block {
  line-height: 60px;
  margin-left: 20px;
}

.list-panel .to-details {
  font-size: 30px;
  color: var(--grey400);
  margin: 15px 0;
}

.list-panel .footer {
  line-height: 60px;
  text-align: center;
  color: var(--grey400);
  cursor: pointer;
}