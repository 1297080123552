#progress-chart-point-popup {
  position: absolute;
  width: 300px;
  z-index: 2;
  font-weight: 500;
}

#progress-chart-point-popup .header-block,
#progress-chart-point-popup .row {
  border-bottom: 1px solid var(--grey300);
}

#progress-chart-point-popup .row {
  padding: 10px 0;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid var(--grey300);
}

#progress-chart-point-popup .header-block {
  padding: 15px 0;
}

#progress-chart-point-popup .header-block .top {
  height: 30px;
  line-height: 30px;
  text-align: center;
}

#progress-chart-point-popup .header-block .bottom {
  height: 20px;
  line-height: 20px;
  color: var(--blue500);
  text-align: center;
  font-size: 25px;
}

#progress-chart-point-popup .row {
  height: 40px;
}

#progress-chart-point-popup .row .left,
#progress-chart-point-popup .row .right {
  display: inline-block;
  height: 100%;
  width: 50%;
}

#progress-chart-point-popup .row .right {
  float: right;
  text-align: right;
}

#progress-chart-point-popup .row .top,
#progress-chart-point-popup .row .bottom {
  height: 50%;
}

#progress-chart-point-popup .single-row {
  line-height: 40px;
  font-size: 20px;
}

#progress-chart-point-popup .single-row .right {
  font-size: 15px;
  vertical-align: middle;
  color: var(--grey400);
}

#progress-chart-point-popup .single-row .right-inner {
  display: inline-block;
  height: 40px;
  cursor: pointer;
}

#progress-chart-point-popup .chart-point-example {
  width: 24px;
  height: 24px;
  margin: 8px 0;
}

#progress-chart-point-popup .single-row .right .label {
  vertical-align: top;
}

#progress-chart-point-popup .single-row .right .to-details {
  line-height: 40px;
  height: 40px;
}

#progress-chart-point-popup .row.note-wrapper {
  height: 140px;
  margin-top: 0;
  margin-bottom: 5px;
  padding: 0;
}

#progress-chart-point-popup .note-wrapper .note {
  height: calc(100% - 10px);
  padding: 5px 0;
  overflow-y: auto;
}

#progress-chart-point-popup .note-wrapper .note-textarea {
  width: calc(100% - 6px);
  height: calc(100% - 10px);
  resize: none;
}