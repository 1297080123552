body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@media print {
  body {
    font-family: "Helvetica Neue", sans-serif;
  }
}
