@media screen {
  #student-progress-targets-list-print {
    display: none;
  }
}

@media only print {
  #student-progress-targets-list-print .left {
    width: 25%;
    margin-right: 40px;
  }

  #student-progress-targets-list-print .left .block {
    font-size: 20px;
    line-height: 30px;
  }

  #student-progress-targets-list-print .left .block:not(:last-child) {
    padding-bottom: 20px;
  }

  #student-progress-targets-list-print .left .block:not(:first-child) {
    margin-top: 25px;
  }

  #student-progress-targets-list-print .left .block:not(:last-child) {
    border-bottom: 2px solid var(--grey300);
  }

  #app #student-progress-targets-list-print header {
    display: block;
    position: inherit;
    height: auto;
    z-index: auto;
    background-color: transparent;
    font-weight: bold;
    font-size: 20px;
  }

  #student-progress-targets-list-print .left .block ul {
    margin: 5px 0 0 0;
    padding-left: 20px;
  }

  #student-progress-targets-list-print .right {
    width: 75%;
  }

  #app #student-progress-targets-list-print .right header {
    text-align: right;
    height: 30px;
  }

  #student-progress-targets-list-print .right .row {
    font-size: 18px;
    border-top: 2px solid var(--grey300);
    padding: 5px 0 5px 10px;
    line-height: 25px;
    display: flex;
    align-items: flex-start;
  }

  #student-progress-targets-list-print .right .row:last-child {
    border-bottom: 2px solid var(--grey300);
  }

  #student-progress-targets-list-print .right .row .circle-icon {
    font-size: 14px;
    margin-right: 5px;
    line-height: 25px;
    height: 25px;
  }

  #student-progress-targets-list-print .right .row .date {
    margin-left: auto;
    width: 95px;
    text-align: right;
  }
}