.student-progress .chart-block-wrapper {
  text-align: center;
}

.student-progress .chart-block {
  display: inline-block;
}

.student-progress .no-points-message {
  margin: 50px 0;
  font-size: 30px;
  color: var(--grey600);
}

/**
* Phase headers styles.
 */
.student-progress .headers {
  text-align: left;
}

.student-progress .header {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  height: 100%;
  padding: 0 5px;
}

/**
* Chart styles.
 */
.student-progress .ct-chart .ct-grid {
  stroke-dasharray: 0;
}

.student-progress .ct-empty {
  display: none;
}

.student-progress .ct-standard {
  stroke: white;
  stroke-width: 4px;
}

.student-progress .ct-standard.ct-outlined {
  fill: none;
  stroke: none;
}

.student-progress .ct-border {
  fill: white;
  stroke-width: 1px;
}

.student-progress .ct-out-rect {
  fill: white;
}

.student-progress .ct-out-circle {
  stroke: white;
  stroke-width: 7px;
}

.student-progress .ct-pretest .ct-triangle-right,
.student-progress .ct-posttest .ct-triangle-left {
  fill: white;
}

/**
* Common page point styles (ChartPointPopup and Print footer)
 */
#student-progress-wrapper .chart-point-example.ct-pretest .ct-triangle-right,
#student-progress-wrapper .chart-point-example.ct-posttest .ct-triangle-left {
  stroke-dasharray: 1, 10, 21;
}