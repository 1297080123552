@media print {
  #app header {
    display: none;
  }

  .side-menu {
    display: none;
  }

  .navigation-bar {
    display: none;
  }
}

#app header .logo-image {
  height: 37px;
}

#app header .logout-btn {
  margin-left: auto;
  border-color: white;
}

#app main {
  position: absolute;
  top: 64px;
  width: 100%;
}

#app .header-body,
#app .content {
  width: 80%;
  margin: 0 auto;
}

#app .content .stand-alone-title {
  margin: 32px 0 9px 0;
  font-size: 2em;
}

@media print {
  #app main {
    top: 0;
  }

  .main {
    width: 100%;
    top: 0;
  }
}