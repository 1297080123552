.print-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.print-page .student-header {
  flex-grow: 0;
  margin-bottom: 15px;
}

.print-page .student-header .avatar {
  color: black;
  font-weight: bold;
  background-color: transparent;
  border: 4px solid black;
}

.print-page .student-progress-print-body {
  flex-grow: 1;
  display: flex;
}

.print-page .student-progress-print-body .error {
  margin: 30px auto 0 auto;
  text-align: center;
  font-size: 30px;
  color: var(--grey600);
}