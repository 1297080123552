#nav-programs-list {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

#nav-programs-list button {
  padding-left: 12px;
}

#nav-programs-list .menu-button-label {
  max-width: 450px;
  font-size: 20px;
  font-weight: normal;
  text-transform: none;
  height: 22px;
}

#nav-programs-list .icon {
  font-size: 22px;
}

#nav-programs-list-menu .list-item {
  padding: 0;
  height: auto;
}

#nav-programs-list-menu .link {
  color: unset;
  text-decoration: none;
  padding: 12px 0 12px 16px;
  width: 100%;
  max-width: 500px;
}