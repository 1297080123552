@media screen {
  /**
  * @note @hack We need this style hack to ensure that `trunk8` plugin can correctly replace multi-line bottom title in
  * phase headers by dots. If we exclude `#student-progress-chart-print` component from rendering using
  * `display: none` the `trunk8` wouldn't know actual sizes of the block and so couldn't replace unnecessary symbols
  * with dots. If `trunk8` would be removed, these styles also may be safely replaced by `display: none`.
  * [aLuk 25.Dec.17]
   */
  #student-progress-chart-print.screen-hidden {
    visibility: hidden;
    position: absolute;
    height: 500px;
    overflow: hidden;
  }
}

#student-progress-chart-print .student-progress-print-body {
  flex-direction: column;
}

#student-progress-chart-print .chart-block {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  margin-left: 14px;
}

#student-progress-chart-print .y-axis-title,
#student-progress-chart-print .x-axis-title {
  font-size: 12px;
  font-weight: bold;
}

#student-progress-chart-print .x-axis-title {
  text-align: center;
  margin-bottom: 10px;
}

#student-progress-chart-print .y-axis-title {
  display: inline-block;
  position: absolute;
  width: 135px;
  margin-top: 215px;
  margin-left: -121px;
  transform: rotate(-90deg);
  transform-origin: right bottom 0;
}

/**
* Overlay
 */
#student-progress-chart-print .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: left;
}

#student-progress-chart-print .overlay .separator {
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

#student-progress-chart-print .overlay .separator:first-child {
  border-left: none;
}

#student-progress-chart-print .overlay .separator:last-child {
  border-right: 2px solid black;
}

/**
* Phase headers styles.
*/
#student-progress-chart-print .headers {
  font-size: 0.8em;
}

#student-progress-chart-print .headers {
  height: 8em;
}

#student-progress-chart-print .header {
  padding: 0 4px;
}

#student-progress-chart-print .header .top-title {
  line-height: 2em;
}

#student-progress-chart-print .header .bottom-title {
  line-height: 1.5em;
}

/**
* Chart footer styles.
*/
#student-progress-chart-print .chart-footers {
  font-size: 0.6em;
  font-weight: bold;
  margin-top: 35px;
  text-align: left;
}

#student-progress-chart-print .chart-footer {
  display: inline-block;
  padding-left: 2px;
  text-align: center;
  transform: translateX(-39%);
}

/**
* Chart styles.
*/
#student-progress-chart-print .ct-chart-line {
  margin-right: 0;
  overflow: visible;
}

#student-progress-chart-print .ct-line {
  stroke: var(--grey400);
  stroke-width: 6px;
}

#student-progress-chart-print .ct-grid {
  stroke: var(--grey300);
}

#student-progress-chart-print .ct-label.ct-vertical.ct-start,
#student-progress-chart-print .ct-label.ct-horizontal.ct-end {
  font-size: 10px;
  font-weight: bold;
  color: black;
}

#student-progress-chart-print .ct-label.ct-vertical.ct-start {
  margin-top: 5px;
}

#student-progress-chart-print .ct-label.ct-horizontal.ct-end {
  width: 47px !important;
  justify-content: flex-end;
  margin-top: 18px;
  margin-left: -18px;
  transform: rotate(-90deg);
}

#student-progress-chart-print .ct-standard {
  fill: black;
}

#student-progress-chart-print .ct-border-rect {
  stroke-dasharray: 1, 8, 16;
}

#student-progress-chart-print .ct-border {
  stroke: black;
}

#student-progress-chart-print .ct-in-circle {
  fill: black;
}

#student-progress-chart-print .ct-triangle-left,
#student-progress-chart-print .ct-triangle-right {
  stroke: black;
}

#student-progress-chart-print .ct-pretest .ct-triangle-right,
#student-progress-chart-print .ct-posttest .ct-triangle-left {
  stroke-dasharray: 1, 6, 21;
}

#student-progress-chart-print .ct-pretest.ct-outlined .ct-triangle-right,
#student-progress-chart-print .ct-posttest.ct-outlined .ct-triangle-left {
  stroke-dasharray: 1, 2, 7;
}

#student-progress-chart-print .ct-pretest .ct-triangle-left,
#student-progress-chart-print .ct-posttest .ct-triangle-right,
#student-progress-chart-print .ct-probe .ct-triangle-right,
#student-progress-chart-print .ct-probe .ct-triangle-left {
  fill: black;
}

#student-progress-chart-print.behavior-chart .ct-grids .ct-vertical {
  display: none;
}

#student-progress-chart-print .progress-chart {
  margin-bottom: 25px;
}

/**
* Page footer.
 */
#student-progress-chart-print footer {
  border-top: 2px solid var(--grey300);
}

#student-progress-chart-print footer .wrapper {
  float: right;
  font-size: 0.8em;
  font-weight: bold;
  margin-top: 3px;
}

#student-progress-chart-print footer .wrapper .label-block {
  display: inline-block;
  margin: 0 5px;
}

#student-progress-chart-print footer .wrapper .label-block div,
#student-progress-chart-print footer .wrapper .label {
  display: inline-block;
  vertical-align: top;
  line-height: 25px;
}

#student-progress-chart-print footer .chart-point-example {
  height: 25px;
  width: 25px;
}