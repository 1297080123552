@media screen {
  #student-progress-phase-list-print {
    display: none;
  }
}

@media only print {
  #student-progress-phase-list-print .student-header {
    margin-bottom: 17px;
  }

  #student-progress-phase-list-print .student-progress-print-body .column {
    display: inline-block;
    margin: 0 15px;
    vertical-align: top;
  }

  #student-progress-phase-list-print .student-progress-print-body .column:first-child {
    margin-left: 0;
  }

  #student-progress-phase-list-print .student-progress-print-body .column:last-child {
    margin-right: 0;
  }

  #student-progress-phase-list-print .student-progress-print-body .block {
    font-size: 17px;
    margin-top: -2px;
  }

  /**
  * Title
   */
  #student-progress-phase-list-print .student-progress-print-body .block .title {
    border-top: 3px solid black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 2px solid var(--grey300);
  }

  #student-progress-phase-list-print .student-progress-print-body .block .title .top {
    display: flex;
    align-items: baseline;
    font-weight: bold;
    margin: 8px 0 12px 0;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .title .top .left {
    font-size: 16px;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .title .top .right {
    margin-left: auto;
    font-size: 12px;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .title .top .right div {
    display: inline-block;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .title .top .right div:last-child {
    margin-left: 10px;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .title .bottom {
    margin-bottom: 5px;
    overflow: hidden;
  }

  /**
  * Body
   */
  #student-progress-phase-list-print .student-progress-print-body .block .body .body-block {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    overflow: hidden;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .body .body-block:first-child {
    padding-top: 7px;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .body .circle-icon {
    font-size: 18px;
    margin-right: 5px;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .body .note-initials {
    font-size: 14px;
    font-weight: bold;
    min-width: 20px;
    height: 20px;
    text-align: center;
    margin-right: 5px;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .body .note {
    border: 1px solid black;
    border-radius: 5px;
    padding: 6px 10px;
  }

  #student-progress-phase-list-print .student-progress-print-body .block .body .body-block .no-items {
    padding-left: 25px;
  }
}