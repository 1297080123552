#student-profile .list-panel .right-text-block {
  width: 110px;
  text-align: right;
}

#student-profile .edit-button {
  margin: auto;
}

.ant-list-item * {
  box-sizing: border-box;
}