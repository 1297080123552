#students-list .students-list-title {
  width: calc(33% + 50px);
}

#students-list .third-width {
  width: 33%;
}

#students-list .download-file-button {
  height: 60px;
  width: 100px;
  padding: 0;
  margin-left: 30px;
  transition: color 0.3s ease-out;
}

#students-list .download-file-button:hover {
  background-color: transparent;
  color: #0b7ad5;
}

#students-list .download-file-button .download-file-icon {
  font-size: 25px;
  margin-left: 5px;
}

#students-list .session-report-button {
  height: 60px;
  width: 181px;
  padding: 0;
  margin-left: 8px;
  margin-bottom: 24px;
  transition: color 0.3s ease-out;
}

#students-list .session-report-button:hover {
  background-color: transparent;
  color: #0b7ad5;
}

#students-list .session-report-button .session-report-icon {
  font-size: 24px;
  margin-left: 8px;
}

#students-list .spinner {
  height: 40px;
  width: 100px;
  margin: 10px 0 0 30px;
}

#students-list__date-range-dialog {
  width: 455px;
  padding: 24px;
}

#students-list__date-range-dialog .checkbox-row,
#students-list__date-range-dialog .date-range-row {
  margin-bottom: 24px;
}

#students-list__date-range-dialog .date-range-row {
  display: flex;
}

#students-list__date-range-dialog .date-range-input {
  width: 215px;
  height: 68px;
}

#students-list__date-range-dialog .date-range-input:first-child {
  margin-right: auto;
}