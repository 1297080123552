@media print {
  #student-progress-screen {
    display: none;
  }
}

@media screen {
  * {
    box-sizing: content-box;
  }

  #student-progress-screen .chart-block-wrapper {
    padding-bottom: 20px;
    z-index: 1;
  }

  #student-progress-screen .chart-block {
    max-width: calc(100% - 50px);
    min-width: 260px;
    height: 530px;
    padding: 0 25px;
    position: relative;
  }

  #student-progress-screen .chart {
    display: inline-block;
    max-width: 100%;
    overflow-y: auto;
  }

  /**
  * Right-side y-axis styles.
   */
  #student-progress-screen .y-axis {
    position: absolute;
    width: 40px;
    height: 389px;
    display: inline-block;
    vertical-align: top;
    padding: 80px 0 0 11px;
    color: var(--grey400);
    font-size: 15px;
    background-color: white;
    transform: translateX(-85px);
  }

  #student-progress-screen .y-axis div {
    height: 34.5px;
    line-height: 35px;
  }

  #student-progress-screen .y-axis div:not(.title):nth-child(odd) {
    transform: translateY(-50%);
  }

  #student-progress-screen .y-axis div:not(.title):nth-child(even) {
    border-top: 1px solid var(--grey400);
    width: 70%;
  }

  #student-progress-screen .y-axis .title {
    position: absolute;
    top: 217px;
    left: -166px;
    width: 385px;
    padding: 0 21px 0 63px;
    background-color: white;
    transform: rotate(90deg);
    font-size: 16px;
  }

  #student-progress-screen .x-axis-title {
    position: absolute;
    left: 25px;
    bottom: 40px;
    width: calc(100% - 135px);
    min-width: 210px;
    color: var(--grey400);
  }

  /**
  * Phase headers styles.
   */
  #student-progress-screen .headers {
    height: 30px;
    line-height: 30px;
    font-size: 40px;
    padding: 35px 50px 0 0;
  }

  #student-progress-screen .header {
    font-size: 15px;
    color: white;
    margin: 0 3px;
    border-radius: 5px;
    background-color: var(--blue300);
    cursor: pointer;
  }

  #student-progress-screen .header.selected {
    background-color: var(--blue500);
  }

  #student-progress-screen .header.dim {
    background-color: transparent;
    color: var(--grey300);
    border: 1px solid var(--grey300);
  }

  #student-progress-screen .header .title {
    height: 100%;
  }

  #student-progress-screen .header .circle-icon {
    font-size: 15px;
  }

  #student-progress-screen .header.dim .circle-icon {
    color: var(--grey300);
  }

  #student-progress-screen .header .top-title-element {
    display: inline-block;
    vertical-align: top;
    line-height: 30px;
    height: 30px;
  }

  /**
  * Chart styles.
   */
  #student-progress-screen .progress-chart {
    text-align: left;
    margin-bottom: 30px;
  }

  #student-progress-screen .ct-chart-line {
    margin-right: 50px;
  }

  #student-progress-screen .ct-line {
    stroke: var(--blue300);
  }

  #student-progress-screen .ct-line.selected {
    stroke: var(--blue500);
  }

  #student-progress-screen .ct-line.dim {
    stroke: var(--grey300);
  }

  #student-progress-screen .ct-grid.ct-separator {
    stroke: rgba(0, 0, 0, 0.7);
    stroke-dasharray: 5px;
  }

  #student-progress-screen .ct-dot {
    cursor: pointer;
  }

  #student-progress-screen .ct-label.ct-horizontal.ct-end {
    font-size: 15px;
    color: var(--grey400);
    justify-content: center;
    margin-top: 10px;
    transform: translateX(-50%);
  }

  #student-progress-screen .ct-standard {
    fill: var(--blue300);
  }

  #student-progress-screen .ct-standard.selected {
    fill: var(--blue500);
  }

  #student-progress-screen .ct-standard.dim {
    fill: var(--grey300);
  }

  #student-progress-screen .ct-border-rect {
    stroke-dasharray: 1, 16, 27;
  }

  #student-progress-screen .ct-border {
    stroke: var(--blue300);
  }

  #student-progress-screen .selected .ct-border {
    stroke: var(--blue500);
  }

  #student-progress-screen .dim .ct-border {
    stroke: var(--grey300);
  }

  #student-progress-screen .ct-in-circle {
    fill: var(--blue300);
  }

  #student-progress-screen .selected .ct-in-circle {
    fill: var(--blue500);
  }

  #student-progress-screen .dim .ct-in-circle {
    fill: var(--grey300);
  }

  #student-progress-screen .ct-triangle-left,
  #student-progress-screen .ct-triangle-right {
    stroke: var(--blue300);
  }

  #student-progress-screen .selected .ct-triangle-left,
  #student-progress-screen .selected .ct-triangle-right {
    stroke: var(--blue500);
  }

  #student-progress-screen .dim .ct-triangle-left,
  #student-progress-screen .dim .ct-triangle-right {
    stroke: var(--grey300);
  }

  #student-progress-screen .ct-pretest .ct-triangle-right,
  #student-progress-screen .ct-posttest .ct-triangle-left {
    stroke-dasharray: 1, 10, 21;
  }

  #student-progress-screen .ct-pretest.ct-outlined .ct-triangle-right,
  #student-progress-screen .ct-posttest.ct-outlined .ct-triangle-left {
    stroke-dasharray: 1, 8, 16;
  }

  #student-progress-screen .ct-pretest .ct-triangle-left,
  #student-progress-screen .ct-posttest .ct-triangle-right,
  #student-progress-screen .ct-probe .ct-triangle-right,
  #student-progress-screen .ct-probe .ct-triangle-left {
    fill: var(--blue300);
  }

  #student-progress-screen .ct-pretest.selected .ct-triangle-left,
  #student-progress-screen .ct-posttest.selected .ct-triangle-right,
  #student-progress-screen .ct-probe.selected .ct-triangle-right,
  #student-progress-screen .ct-probe.selected .ct-triangle-left {
    fill: var(--blue500);
  }

  #student-progress-screen .ct-pretest.dim .ct-triangle-left,
  #student-progress-screen .ct-posttest.dim .ct-triangle-right,
  #student-progress-screen .ct-probe.dim .ct-triangle-right,
  #student-progress-screen .ct-probe.dim .ct-triangle-left {
    fill: var(--grey300);
  }
}