.student-header {
  display: flex;
  height: 100px;
  padding: 15px 0;
  box-sizing: content-box;
}

.student-header .avatar {
  margin: 10px 0;
  height: 80px;
  width: 80px;
  font-size: 40px;
}

.student-header .description {
  width: calc(100% - 110px);
  margin-left: auto;
}

.student-header .description .header-node-title {
  font-weight: bold;
}

.student-header .profile-text,
.student-header .info {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
}

.student-header .info {
  text-align: right;
}

.student-header .profile-text .top {
  font-size: 2em;
  line-height: 64px;
}

.student-header .profile-text .bottom-wrapper {
  cursor: pointer;
}

.student-header .top,
.student-header .bottom {
  height: 50%;
  width: 100%;
}

.student-header .top {
  font-size: 23px;
  line-height: 70px;
}

.student-header .bottom {
  font-size: 20px;
  line-height: 30px;
}

.student-header .to-details {
  vertical-align: text-top;
}

.student-header .info .top {
  color: var(--blue500);
}

.student-header .info .top,
.student-header .info .bottom {
  float: right;
}

@media print {
  .student-header {
    color: black;
    padding: 0;
  }

  .student-header .profile-text {
    width: 70%;
  }

  .student-header .info {
    width: 30%;
  }

  .student-header .top,
  .student-header .bottom {
    color: black !important;
  }

  .student-header .profile-text .top {
    font-weight: bold;
  }

  .student-header .info .top,
  .student-header .info .bottom {
    font-size: 18px;
  }

  .student-header .to-details {
    display: none !important;
  }
}