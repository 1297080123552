:root {
  --blue300: #64B5F6;
  --blue500: #2196F3;
  --grey300: #E0E0E0;
  --grey400: #BDBDBD;
  --grey600: #757575;
}

body {
  color: rgba(0, 0, 0, 0.87);
}

.hidden {
  display: none !important;
}

.ellipsis-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.progress-bar {
  display: block !important;
  margin: 70px auto 0;
}

.ant-form label {
  font-size: 16px;
  font-weight: 500;
}