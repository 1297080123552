.page-navigation {
  line-height: 20px;
  font-size: 18px;
  padding-top: 40px;
  color: var(--blue500);
}

.page-navigation .arrow-left-icon {
  vertical-align: top;
  font-size: 20px;
}

.page-navigation a {
  text-decoration: none;
}

.page-navigation a:visited {
  color: var(--blue500);
}